.second-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px; /* Adjust the margin as needed */
  }
  
  .second-section img {
    width: 30%; /* Adjust the width as needed */
    height: auto; /* Maintain image aspect ratio */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .second-section {
      flex-direction: column;
    }
  
    .second-section img {
      width: 100%;
      margin-bottom: 10px; /* Adjust the margin as needed */
    }
  }
  