/* Header.css */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100px;
  background: url('../images/bg-section.png') no-repeat center center fixed;
  background-size: cover; /* Para cubrir toda la pantalla */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

.logo img {
  height: 80px;
  margin-left: 50px;
}

.menu a {
  padding: 5px 20px; /* Ajusta el padding para hacer los botones más grandes */
  transition: transform 0.3s ease; /* Cambia la propiedad a 'transform' */
  text-decoration: none; /* Quitamos la decoración de texto por defecto en los enlaces */
  color: black;
}

.menu {
  margin-right: 100px; /* Ajusta el margen a la derecha para evitar que se salgan de la pantalla */
  display: flex;
}

.menu-item {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: white;
}

.menu-item p {
  margin: 5px 0 0;
  font-size: 14px;
  color: rgb(0, 0, 0) !important;
}

.menu img {
  height: 80px;
  transition: box-shadow 0.3s ease;
}

.menu img:not(:first-child) {
  display: none;
}

.menu:hover img {
}

.menu img:hover {
  transform: scale(1.1); /* Ajusta el factor de escala según tus preferencias */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7); /* Efecto de brillo */

}

/* Estilos para hacer el header responsive */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: stretch; /* Ajuste aquí para evitar la alineación central en el eje transversal */
  }

  .menu {
    margin-top: 10px;
    align-items: center;
  }

  .menu a {
    margin-left: 0;
    margin-top: 5px;
  }

  .menu img {
    margin-left: 0;
    margin-top: 5px;
    height: 40px;
  }
}
