.first-section {
  padding: 50px 20px;
}

.content {
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.image {
  width: 100%;
  text-align: center;
}

.image h2 {
  font-size: 32px;
  margin-bottom: 15px;
  color:  rgb(255, 255, 255);
  -webkit-text-stroke: 1px  rgb(56, 140, 156);
  text-stroke: 1px rgb(56, 98, 156);
  display: inline-block;
  padding: 5px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  background: url("../images/microwabetable.png") center/cover no-repeat;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 50px;
}

.image-container:hover {
  animation: shake 0.5s ease-in-out infinite;
  filter: brightness(1.2); /* Brillo al hacer hover */
  transform: scale(1.1); /* Escala al hacer hover */
}

/* Estilos para hacer responsive */
@media (max-width: 768px) {
  .image h2 {
    font-size: 24px;
  }
}

/* Animación de vibración */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}
